<div class="switch-add-profile-container" data-cy="select-category-mobile">
  <div class="title">{{ 'PWA_homeLibrary_filters_categories_header' | translate }}</div>

  <div class="options-container">
    @for (category of bookShelfService.categoriesBooks$ | async; track $index) {
      <div class="option" (click)="goToShelfPage(category)" data-cy="select-category-mobile-modal-option">
        {{ backendTranslate(category.name) }}
      </div>
    }
  </div>
</div>
