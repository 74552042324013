import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ProfileState } from '../../store/profile.state';
import { AppService } from '../../services/app.service';
import { UserState } from '../../store/user.state';
import { IonHeader, IonMenuButton } from '@ionic/angular/standalone';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation.service';
import { ComponentRef } from '@ionic/core';
import { ProfileShortcutModalPage } from './modals/profile-shortcut/profile-shortcut-modal.page';
import { ProfileConfirmationPromptModalPage } from './modals/profile-confirmation-prompt/profile-confirmation-prompt-modal.page';
import { ModalService } from '../../services/modal.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [IonHeader, AsyncPipe, TranslateModule, RouterLink, IonMenuButton],
})
export class HeaderComponent {
  @Input() hide = false;
  @Input() customTitle?: string;
  @Input() showAvatar: boolean = true;
  @Input() showBackButton: boolean = true;
  @Output() navigateBack = new EventEmitter<void>();

  constructor(
    public profileState: ProfileState,
    public userState: UserState,
    public appService: AppService,
    private navigationService: NavigationService,
    private modalService: ModalService,
    private router: Router,
  ) {}

  onNavigateBack(): void {
    this.navigateBack.emit();
  }

  onNavigateToProfile(): void {
    void this.navigationService.navigate('profile');
  }

  async onOptionalDisplayProfileShortCut(): Promise<void> {
    console.log(this.router.url);

    if (this.router.url === '/profile' || this.router.url === '/profile/select') {
      return;
    } else if (this.router.url === '/profile/create' || this.router.url === '/profile/edit') {
      const confirmationModal = await this.modalService.create({
        component: ProfileConfirmationPromptModalPage as ComponentRef,
        cssClass: 'wr-modal',
        componentProps: {
          type: this.router.url === '/profile/create' ? 'creation' : 'edition',
        },
      });

      await confirmationModal.present();

      const data = await confirmationModal.onDidDismiss();

      if (data.data && !data.data.cancel) {
        void this.navigationService.navigateRoot('/profile');
      }

      return;
    }

    const breakPoints = this.appService.isMobile$.value ? { breakpoints: [0, 1], initialBreakpoint: 1 } : {};

    const ageFilterModal = await this.modalService.create({
      component: ProfileShortcutModalPage as ComponentRef,
      cssClass: 'wr-modal ' + (this.appService.isMobile$.value ? 'mobile-profile-shortcut' : 'desktop-profile-shortcut'),
      ...breakPoints,
    });

    await ageFilterModal.present();
  }

  onNavigateToAuthentication(): void {
    void this.navigationService.navigate('authentication');
  }
}
