<div class="filters-container" [class.mobile]="appService.isMobile$ | async" data-cy="book-filters-modal">
  <div class="close-modal" (click)="closeModal()">
    <img src="assets/icon/blue-close.svg" alt="" />
  </div>

  <div class="title">{{ 'PWA_libraryFilters_title' | translate }}</div>
  <div class="description">{{ 'PWA_libraryFilters_ageRange_selection_header' | translate }}</div>

  <div class="grade-container">
    <div data-cy="grade-option" class="grade" [class.active]="selectedGradeId === null" (click)="onSelectedGradeChange(null)">
      {{ 'PWA_libraryFilters_ageRange_selection_all' | translate }}
    </div>
    @for (gradeFilter of gradeFilters; track $index) {
      <div data-cy="grade-option" class="grade" [class.active]="gradeFilter.id === selectedGradeId" (click)="onSelectedGradeChange(gradeFilter.id)">
        {{ 'PWA_libraryFilters_ageRange_selection' | translate: { minAge: gradeFilter.minAge, maxAge: gradeFilter.maxAge } }}
      </div>
    }
  </div>

  <div class="separator"></div>

  <div class="description">{{ 'PWA_libraryFilters_language_selection_header' | translate }}</div>

  <ion-radio-group [value]="selectedLanguageCode" (ionChange)="onSelectedLanguageChange($event)">
    <ion-radio mode="md" justify="start" label-placement="end" [value]="null" data-cy="language-option">
      {{ 'PWA_homeLibrary_filters_language' | translate }}
    </ion-radio>
    @for (languageFilter of languageFilters; track $index) {
      <ion-radio mode="md" justify="start" label-placement="end" [value]="languageFilter.code" data-cy="language-option">
        {{ languageFilter.name }}
      </ion-radio>
    }
  </ion-radio-group>

  <div class="separator"></div>

  <app-button
    [label]="'PWA_libraryFilters_CTA_applyFilters' | translate"
    (clicked)="applyFilter()"
    [uppercase]="true"
    [color]="'green'"
    [bold]="true"
    [size]="'small'"
    data-cy="filters-confirm-btn" />

  <div class="reset-filters" data-cy="book-filters-reset-btn" (click)="resetFilters()">
    {{ 'PWA_libraryFilters_CTA_link_resetFilters' | translate }}
    <img src="assets/icon/trash-outline-blue.svg" alt="" />
  </div>
</div>
