<div class="modal-container">
  <div class="close-modal" (click)="closeModal()" data-cy="logout-close-modal-btn">
    <img src="assets/icon/blue-close.svg" alt="" />
  </div>

  <div class="title">{{ 'PWA_logout_popup_header' | translate }}</div>
  <div class="description">{{ 'PWA_logout_popup_body' | translate }}</div>

  <app-button (click)="closeModal()" data-cy="logout-cancel-logout-btn" [color]="'orange'" [size]="'normal'" [label]="'PWA_logout_popup_CTA_Cancel' | translate" />

  <div class="cancel" (click)="logout()" data-cy="logout-confirm-btn">{{ 'PWA_logout_popup_CTA_link_yesLogout' | translate }}</div>
</div>
