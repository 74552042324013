import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BookShelfComponent } from '../book-shelf/book-shelf.component';
import { BookShelfService, ShelfType } from '../../services/book-shelf.service';
import { BookCategory } from '../../models/book.model';
import { filter, Observable, switchMap } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-book-categories-shelves',
  templateUrl: './book-categories-shelves.component.html',
  styleUrls: ['./book-categories-shelves.component.scss'],
  imports: [BookShelfComponent, AsyncPipe],
})
export class BookCategoriesShelvesComponent {
  constructor(private bookShelfService: BookShelfService) {}

  bookCategories$: Observable<BookCategory[]> = this.bookShelfService.initialized$.pipe(
    filter(initialized => initialized),
    switchMap(() => this.bookShelfService.categoriesBooks$),
  );

  readonly shelfType = ShelfType;
  protected readonly ShelfType = ShelfType;
}
