<div class="profile-shortcut-container" [class.mobile]="appService.isMobile$ | async" data-cy="profile-shortcut-modal">
  @if ((appService.isMobile$ | async) === false) {
    <div class="close-modal" (click)="closeModal()" data-cy="profile-shortcut-desktop-close">
      <img src="assets/icon/blue-close.svg" alt="" />
    </div>
  }

  <div class="current-profile">
    <div class="left" [class.mobile]="appService.isMobile$ | async">
      @if ((profileState.currentProfile$ | async)?.isFamilyProfile) {
        <img src="assets/avatars/family-avatar.svg" alt="" class="current-avatar" />
      } @else {
        <img src="assets/avatars/{{ (profileState.currentProfile$ | async)?.avatar }}" alt="" class="current-avatar" />
      }

      <span class="current-name" [class.desktop]="(appService.isMobile$ | async) === false">
        <div data-cy="profile-shortcut-current-profile-name">
          {{ 'PWA_switchProfile_popup_currentProfile' | translate: { profileName: (profileState.currentProfile$ | async)?.name } }}
        </div>
        @if ((appService.isMobile$ | async) === false) {
          <app-button
            data-cy="profile-shortcut-go-profile"
            (clicked)="goToProfile()"
            [icon]="'assets/icon/right-arrow.svg'"
            [iconPlacement]="'end'"
            [uppercase]="true"
            [color]="'blue'"
            [size]="'small'"
            [label]="'See profile'" />
        }
      </span>
    </div>

    @if (appService.isMobile$ | async) {
      <div class="button">
        <app-button
          data-cy="profile-shortcut-go-profile"
          (clicked)="goToProfile()"
          [icon]="'assets/icon/right-arrow.svg'"
          [shape]="'circle'"
          [color]="'blue'"
          [size]="'small'" />
      </div>
    }
  </div>

  <div class="switch-reader">
    <img src="assets/icon/blue-change.svg" alt="" />
    {{ 'PWA_switchProfile_CTA_switchProfile' | translate }}
  </div>

  <div class="options-container">
    @for (profile of profileState.profiles$ | async; track profile.id) {
      @if (profile.id !== (profileState.currentProfile$ | async)?.id) {
        <div class="option" (click)="selectProfile(profile)" data-cy="profile-shortcut-option">
          <div class="profile">
            @if (profile.isFamilyProfile) {
              <img src="assets/avatars/family-avatar.svg" alt="" class="avatar" />
              <span class="profile-name read-together-option" data-cy="profile-shortcut-option-name">{{
                'PWA_readerSelection_selection_readTogether_header' | translate
              }}</span>
            } @else {
              <img src="assets/avatars/{{ profile.avatar }}" alt="" class="avatar" />
              <span class="profile-name" data-cy="profile-shortcut-option-name">{{ profile.name }}</span>
            }
          </div>
        </div>
      }
    }
  </div>

  <div class="add-profile">
    <app-button
      data-cy="profile-shortcut-add-profile"
      (clicked)="goToCreateProfile()"
      [color]="'blue'"
      [icon]="'assets/icon/white-person-add.svg'"
      [label]="'PWA_switchProfile_CTA_link_newProfile' | translate"
      [size]="'small'"
      [iconPlacement]="'end'"
      [uppercase]="true" />
  </div>
</div>
