import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AppService } from '../../../../services/app.service';
import { ButtonComponent } from '../../../../components/button/button.component';
import { NavigationService } from '../../../../services/navigation.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss'],
  imports: [AsyncPipe, TranslateModule, ButtonComponent],
})
export class TipsComponent {
  constructor(
    public appService: AppService,
    private navigationService: NavigationService,
  ) {}

  goToVroomTips(): void {
    void this.navigationService.navigate('tips');
  }
}
