import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { AnalyticsEventType, ModalViewEvents } from '../../../../services/analytics/analytics.model';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { BookShelfService } from '../../../../services/book-shelf.service';
import { LanguageService } from '../../../../services/language.service';
import { NavigationService } from '../../../../services/navigation.service';
import { Translatable } from '../../../../models/translation.model';
import { BookCategory } from '../../../../models/book.model';
import { ModalService } from '../../../../services/modal.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-select-category-mobile-modal',
  templateUrl: './select-category-mobile-modal.page.html',
  styleUrls: ['./select-category-mobile-modal.page.scss'],
  imports: [TranslateModule, AsyncPipe],
})
export class SelectCategoryMobileModalPage {
  constructor(
    public bookShelfService: BookShelfService,
    public languageService: LanguageService,
    private analyticsService: AnalyticsService,
    private modalService: ModalService,
    private navigationService: NavigationService,
  ) {}

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.SelectCategoryModal });
  }

  goToShelfPage(category: BookCategory): void {
    void this.navigationService.navigate('shelf/category/' + category.id, {
      state: { title: this.backendTranslate(category.name) },
    });
    void this.modalService.dismiss();
  }

  backendTranslate(translatable: Translatable): string {
    return this.languageService.translateBackendCopy(translatable);
  }
}
