import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError, timer } from 'rxjs';
import { retry } from 'rxjs/operators';
import { Feature, LoggerService } from '../services/logger.service';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  constructor(private loggerService: LoggerService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // If the IP api is not working, we don't want to retry it multiple times. We set USA as the user country
    if (req.url.includes('pro.ip-api')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      retry({
        count: 10,
        delay: (error, retryCount) => {
          if (error instanceof HttpErrorResponse && (error.status === 0 || !error.status)) {
            return timer(retryCount * 1000);
          }
          throw error;
        },
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0 || !error.status) {
          this.loggerService.warning('RetryInterceptor::intercept - Request not sent', {
            feature: Feature.API,
            context: 'Status: ' + error.status + ', url:' + error.url + ', message: ' + error.message,
          });
        }

        return throwError(() => error);
      }),
    );
  }
}
