<div class="modal-container" data-cy="profile-confirmation-prompt-modal">
  <div class="close-modal" (click)="cancel()" data-cy="profile-confirmation-prompt-modal-close-btn">
    <img src="assets/icon/blue-close.svg" alt="" />
  </div>

  <div class="title">{{ title | translate }}</div>
  <div class="description">{{ description | translate }}</div>

  <app-button (click)="cancel()" data-cy="profile-confirmation-prompt-modal-cancel-btn" [color]="'orange'" [size]="'normal'" [label]="cancelTitle | translate" />

  <div class="cancel" (click)="confirm()" data-cy="profile-confirmation-prompt-modal-confirm-btn">{{ confirmTitle | translate }}</div>
</div>
