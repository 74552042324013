import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { fadeInOut } from '../../../utils/animations';
import { IonInput } from '@ionic/angular/standalone';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-authentication-password',
  templateUrl: './authentication-password.component.html',
  styleUrls: ['./authentication-password.component.scss'],
  animations: [fadeInOut],
  imports: [IonInput, TranslateModule],
})
export class AuthenticationPasswordComponent implements OnInit {
  @Input({ required: true }) value!: string;
  @Input({ required: true }) showError!: boolean;
  @Input({ required: true }) placeholder!: string;

  @Input() showValidation = true;

  @Output() valueChanged = new EventEmitter<string>();
  @Output() passwordConfirmed = new EventEmitter<void>();

  showPassword: boolean = false;

  constructor(private ref: ChangeDetectorRef) {}

  passwordRuleErrors = {
    length: false,
    symbol: false,
    capital: false,
  };

  get passwordIcon(): string {
    if (this.value) {
      return this.showPassword ? 'assets/icon/eye-red.svg' : 'assets/icon/eye-off-red.svg';
    }
    return 'assets/icon/eye-off.svg';
  }

  ngOnInit(): void {
    this.checkPasswordRuleErrors(this.value);
    this.ref.detectChanges();
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  onPasswordChange(event: CustomEvent): void {
    this.checkPasswordRuleErrors(event.detail.value);
    this.valueChanged.emit(event.detail.value);
    this.ref.detectChanges();
  }

  onConfirm(): void {
    this.passwordConfirmed.emit();
  }

  checkPasswordRuleErrors(value: string): void {
    this.passwordRuleErrors = {
      length: value.length > 8,
      symbol: !!value.match(/^(?=.*[\d\W]).+$/),
      capital: !!value.match(/(?=.*[A-Z])/),
    };
  }
}
