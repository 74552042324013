import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClientService } from './http-client.service';
import CryptoJS from 'crypto-js';
import { Feature, LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(
    private httpClientService: HttpClientService,
    private loggerService: LoggerService,
  ) {}

  generateQueryToken(path: string, time: number, clientToken: string): string {
    const value = `${clientToken}${path}${time}`;
    const hashedValue = CryptoJS.SHA256(value).toString();
    return hashedValue + '-' + time;
  }

  getBookImage(params: {
    uuid: string;
    version: number;
    projectId: number;
    countryCode: string;
    pathType: 'full_path' | 'path';
    imagePath: string;
    size: string | null;
  }): string {
    if (!params.imagePath) {
      this.loggerService.error('ImageService::getBookImage - missing imagePath key', {
        context: 'Missing imagePathKey for book uuid : ' + params.uuid + ' (projectId:' + params.projectId + ')',
        feature: Feature.API,
      });
      return '';
    }

    const imageSize = params.size != null ? `&size=${params.size}` : '';
    params.imagePath = encodeURIComponent(params.imagePath.replace('../', ''));

    let fullPath = `/books/${params.uuid}/${params.version}/images?project_id=${params.projectId}&country=${params.countryCode}&${params.pathType}=${params.imagePath}${imageSize}`;
    const token = this.generateQueryToken('/v1' + fullPath, Date.now(), this.httpClientService.getClientCodeAndToken().clientToken);

    fullPath += '&token=' + token;

    return environment.booksBaseUrl + fullPath;
  }
}
