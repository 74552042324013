import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { fadeIn, fadeInOut } from '../../../../utils/animations';
import { CompressedImagePipe } from '../../../../pipes/compressed-image.pipe';
import { RouterLink } from '@angular/router';
import { Book } from '../../../../models/book.model';
import { ShelfType } from '../../../../services/book-shelf.service';
import { AppService } from '../../../../services/app.service';
import { AsyncPipe } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-book-shelf-cover-image',
  templateUrl: './book-shelf-cover-image.component.html',
  styleUrls: ['./book-shelf-cover-image.component.scss'],
  animations: [fadeInOut, fadeIn],
  imports: [CompressedImagePipe, RouterLink, AsyncPipe],
})
export class BookShelfCoverImageComponent implements OnInit {
  // If no book is passed, we display the placeholder value
  // Needed to calculate the height of the swiper shelf
  @Input() book?: Book;
  @Input({ required: true }) type!: ShelfType;
  @Input() theme: 'white' | 'blue' = 'white';

  hidden = true;

  constructor(
    public appService: AppService,
    private ref: ChangeDetectorRef,
  ) {}

  // FadeIn animation when images are loaded
  onLoad(id: string): void {
    const imgElement = document.getElementById(id);
    if (imgElement) {
      imgElement.classList.add('hidden');

      setTimeout(() => {
        imgElement.classList.add('transition');
        imgElement.classList.add('visible');
      }, 75);
    }
  }

  ngOnInit(): void {
    // This fix a bug in swiper-slide where the width of each slide is calculated
    // Only after the element has been added to the DOM
    setTimeout(() => {
      this.hidden = false;
      this.ref.detectChanges();
    }, 1);
  }
}
