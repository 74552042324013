import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { BannerService } from '../../services/banner.service';
import { Platform } from '@ionic/angular/standalone';
import { BrowserService } from '../../services/browser.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-native-app-installation-banner',
  templateUrl: './native-app-installation-banner.page.html',
  styleUrls: ['./native-app-installation-banner.page.scss'],
  imports: [ButtonComponent, TranslateModule],
})
export class NativeAppInstallationBannerComponent {
  constructor(
    private bannerService: BannerService,
    private platform: Platform,
    private browserService: BrowserService,
  ) {}

  get buttonSize(): 'xsmall' | 'small' {
    return window.innerWidth < 400 ? 'xsmall' : 'small';
  }

  closeModal(): void {
    this.bannerService.destroyOptionalAppInstallationBanner();
  }

  openNativeStore(): void {
    this.browserService.goTo(
      this.platform.is('android')
        ? 'https://play.google.com/store/apps/details?id=org.worldreader.readtokids'
        : 'https://apps.apple.com/us/app/booksmart-by-worldreader/id1629873557',
    );
  }
}
