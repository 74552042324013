import { Pipe, PipeTransform } from '@angular/core';
import { ImageService } from '../services/image.service';
import { Book } from '../models/book.model';
import { DefaultProjectState } from '../store/default-project.state';
import { UserLocationState } from '../store/user-location.state';
import { NetworkService } from '../services/network.service';
import { AppService, PlatformType } from '../services/app.service';

enum CompressedImageSize {
  Xxs = '60x80',
  Xs = '120x160',
  S = '240x320',
  M = '480x800',
  L = '720x1280',
  Xl = '768x1280',
  Xxl = '1080x1920',
}

@Pipe({ name: 'compressedImage', standalone: true })
export class CompressedImagePipe implements PipeTransform {
  constructor(
    private imageService: ImageService,
    private projectState: DefaultProjectState,
    private userLocationState: UserLocationState,
    private networkService: NetworkService,
    private appService: AppService,
  ) {}

  transform(value: Book): string {
    return this.imageService.getBookImage({
      uuid: value.uuid,
      version: value.version,
      projectId: this.projectState.defaultProject$.value.id,
      countryCode: this.userLocationState.userLocation$.value.countryCode,
      pathType: 'full_path',
      imagePath: value.coverImagePath,
      size: this.getSize(),
    });
  }

  private getSize(): CompressedImageSize {
    if (this.networkService.connectionType$.value === 'wifi') {
      if (this.appService.platformType === PlatformType.WebDesktop) {
        return CompressedImageSize.M;
      } else {
        return CompressedImageSize.S;
      }
      // Firefox can't detect connectionType. In this case, we check the resoltion of the screen
    } else if (!this.appService.isMobile$.value && navigator.userAgent?.toLowerCase().includes('firefox')) {
      return CompressedImageSize.M;
    }

    return CompressedImageSize.Xs;
  }
}
