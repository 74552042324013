import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppService } from '../../../../services/app.service';
import { AsyncPipe } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ProfileState } from '../../../../store/profile.state';
import { Profile } from '../../../../models/profile.model';
import { ButtonComponent } from '../../../button/button.component';
import { NavigationService } from '../../../../services/navigation.service';
import { AnalyticsEventType, ModalViewEvents } from '../../../../services/analytics/analytics.model';
import { ProfileService } from '../../../../services/profile.service';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { ToastService, ToastType } from '../../../../services/toast.service';
import { ModalService } from '../../../../services/modal.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-profile-shortcut-modal',
  templateUrl: './profile-shortcut-modal.page.html',
  styleUrls: ['./profile-shortcut-modal.page.scss'],
  imports: [AsyncPipe, TranslatePipe, ButtonComponent],
})
export class ProfileShortcutModalPage {
  constructor(
    public appService: AppService,
    public profileState: ProfileState,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private profileService: ProfileService,
    private analyticsService: AnalyticsService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.ProfileShortCutModal });
  }

  async selectProfile(profile: Profile): Promise<void> {
    await this.profileService.setProfile(profile);
    this.analyticsService.sendEvent(AnalyticsEventType.SelectedUserProfile, null);
    void this.toastService.present({
      message: this.translateService.instant('PWA_switchProfile_toast_confirmation'),
      type: ToastType.Positive,
      displayClose: true,
      icon: 'assets/icon/checkmark.svg',
      duration: 2000,
    });
    void this.modalService.dismiss();
  }

  goToCreateProfile(): void {
    void this.modalService.dismiss();
    void this.navigationService.navigate('/profile/create');
  }

  goToProfile(): void {
    void this.modalService.dismiss();
    void this.navigationService.navigate('/profile');
  }

  closeModal(): void {
    void this.modalService.dismiss();
  }
}
