import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonRadio, IonRadioGroup } from '@ionic/angular/standalone';
import { BookFiltersState } from '../../../../store/book-filters.state';
import { AnalyticsEventType, ModalViewEvents } from '../../../../services/analytics/analytics.model';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../button/button.component';
import { AppService } from '../../../../services/app.service';
import { AsyncPipe } from '@angular/common';
import { ModalService } from '../../../../services/modal.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-book-filters-modal',
  templateUrl: './book-filters-modal.page.html',
  styleUrls: ['./book-filters-modal.page.scss'],
  imports: [IonRadioGroup, TranslateModule, IonRadio, ButtonComponent, AsyncPipe],
})
export class BookFiltersModalPage {
  constructor(
    public appService: AppService,
    private bookFilters: BookFiltersState,
    private modalService: ModalService,
    private analyticsService: AnalyticsService,
  ) {}

  languageFilters = this.bookFilters.bookFilters$.value.languages;
  selectedLanguageCode = this.bookFilters.bookFilters$.value.selectedLanguage?.code || null;

  gradeFilters = this.bookFilters.bookFilters$.value.grades;
  selectedGradeId = this.bookFilters.bookFilters$.value.selectedGrade?.id || null;

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.ContentLanguageSelectionModal });
  }

  async applyFilter(): Promise<void> {
    const newSelectedLanguageCode = this.languageFilters.find(language => language.code === this.selectedLanguageCode);
    const newSelectedGradeId = this.gradeFilters.find(grade => grade.id === this.selectedGradeId);

    if (this.bookFilters.bookFilters$.value.selectedLanguage !== newSelectedLanguageCode) {
      await this.bookFilters.set({ selectedLanguage: newSelectedLanguageCode });
    }

    if (this.bookFilters.bookFilters$.value.selectedGrade !== newSelectedGradeId) {
      await this.bookFilters.set({ selectedGrade: newSelectedGradeId });
    }

    this.closeModal();
  }

  closeModal(): void {
    void this.modalService.dismiss();
  }

  onSelectedLanguageChange(event: CustomEvent): void {
    this.selectedLanguageCode = event.detail.value;
  }

  onSelectedGradeChange(gradeId: number | null): void {
    this.selectedGradeId = gradeId;
  }

  async resetFilters(): Promise<void> {
    await this.bookFilters.set({ selectedGrade: null });
    await this.bookFilters.set({ selectedLanguage: null });

    this.closeModal();
  }
}
