<div class="main-container">
  <form [formGroup]="searchForm" class="main-container">
    @if ((appService.isMobile$ | async) && !activeSearch) {
      <div class="search-mobile-left">
        <app-button data-cy="search-mobile-btn" [icon]="'assets/icon/search.svg'" [color]="'white'" (clicked)="focusSearch(true)" [shape]="'circle'" [size]="'xsmall'" />

        <app-button
          data-cy="search-mobile-category-btn"
          class="category-button"
          [icon]="'assets/icon/book.svg'"
          [color]="'blue'"
          (clicked)="openCategoriesModal(true)"
          [shape]="'circle'"
          [size]="'xsmall'" />
      </div>
    }

    @if (((appService.isMobile$ | async) && activeSearch) || (appService.isMobile$ | async) === false) {
      <div class="search-container" [class.mobile]="appService.isMobile$ | async">
        <div class="search">
          <ion-input
            data-cy="search-input"
            (keyup.escape)="focusSearch(false)"
            formControlName="search"
            (ionFocus)="focusSearch(true)"
            class="search"
            [class.desktop-search-not-active]="(appService.isMobile$ | async) === false && !activeSearch"
            [class.desktop-search-active]="(appService.isMobile$ | async) === false && activeSearch"
            [placeholder]="'Search_searchBar_FindABook' | translate"
            #search>
            <img src="assets/icon/search.svg" slot="start" />
          </ion-input>
          @if (activeSearch) {
            <div data-cy="search-cancel" class="cancel" (click)="focusSearch(false)">{{ 'search_cancelSearch' | translate }}</div>
          } @else {
            <app-button
              data-cy="search-desktop-category-btn"
              class="category-button"
              [icon]="'assets/icon/book.svg'"
              [color]="'blue'"
              (clicked)="openCategoriesModal(false)"
              [shape]="'circle'"
              [size]="'xsmall'" />
          }
        </div>

        @if ((searchState.search$ | async)?.history?.length && activeSearch) {
          <div class="history" [@fadeIn] data-cy="search-history">
            <div class="history-header">
              <div class="history-title">{{ 'PWA_search_recentSearches_header' | translate }}</div>
              <div class="history-clear" data-cy="search-clear-history" (click)="clearSearchHistory()">
                {{ 'PWA_search_recentSearches_CTA_link_clear' | translate }}
              </div>
            </div>

            <div class="history-content">
              @for (search of (searchState.search$ | async)?.history; track $index) {
                <div class="history-word" [@fadeInOut] (click)="historySearch(search)" data-cy="search-history-word">{{ search }}</div>
              }
            </div>
          </div>
        }
      </div>
    }

    @if (!activeSearch) {
      <app-book-filters [@fadeIn] data-cy="search-book-filters" />
    }
  </form>

  @if (activeSearch) {
    <div class="results-container" data-cy="search-results">
      @if (isLoading) {
        <div class="spinner-container">
          <ion-spinner name="dots"></ion-spinner>
        </div>
      }

      @if (!isLoading) {
        @if (books?.length) {
          <div data-cy="search-result-books">
            <div class="title">{{ 'PWA_search_results_header' | translate }}</div>
            <app-book-grid [books]="books || []"></app-book-grid>
          </div>
        }

        @if (books?.length === 0) {
          <div class="title no-result" [@fadeInOut] data-cy="search-no-results">{{ 'PWA_search_NoResults_header' | translate }}</div>
        }

        <div class="popular-books" data-cy="search-no-results-popular-books" [class.mobile]="appService.isMobile$ | async" [class.hidden]="books && books.length > 0">
          <div class="title">{{ 'PWA_search_suggestion_checkPopular' | translate }}</div>
          <app-book-grid [books]="popularBooks"></app-book-grid>
        </div>
      }
    </div>
  }
</div>
