import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnalyticsEventType, ModalViewEvents } from '../../services/analytics/analytics.model';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ButtonComponent } from '../../components/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation.service';
import { ModalService } from '../../services/modal.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.page.html',
  styleUrls: ['./delete-account-modal.page.scss'],
  imports: [ButtonComponent, TranslateModule],
})
export class DeleteAccountModalPage {
  constructor(
    private navigationService: NavigationService,
    private modalService: ModalService,
    private analyticsService: AnalyticsService,
  ) {}

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.DeleteAccountModal });
  }

  closeModal(): void {
    void this.modalService.dismiss();
  }

  goToAccountDeletion(): void {
    void this.modalService.dismiss();
    void this.navigationService.navigate('delete-account');
  }
}
