import { Injectable } from '@angular/core';
import { User, UserState, UserType } from '../store/user.state';
import { BookShelfService } from './book-shelf.service';
import { ProfileService } from './profile.service';
import { NavigationService } from './navigation.service';
import { SocialLoginService } from './social-login.service';
import { MixpanelService } from './mixpanel.service';

/**
 * This service has been created in order to avoid circular dependency injection (UserState <-> ProfileService)
 * And to simplify the user.state file
 */
@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private userState: UserState,
    private profileService: ProfileService,
    private navigationService: NavigationService,
    private bookShelfService: BookShelfService,
    private socialLoginService: SocialLoginService,
    private mixpanelService: MixpanelService,
  ) {}

  async logout(redirectToHome = true): Promise<void> {
    const user = this.userState.user$.value;
    // We only allow logout if the user is not a guest
    if (user.userType !== UserType.GUEST) {
      // We logout the user if logged in with a social authentication method
      void this.socialLoginService.logout(user.userType);

      // We set the guestId & the jwt the user had before he logged in
      if (user.guestCredentials) {
        await this.userState.set({
          userType: UserType.GUEST,
          id: user.guestCredentials.id,
          jwt: user.guestCredentials.jwt,
        });

        // We need to delete the current profile on logout
        await this.profileService.reset();
      } else {
        await this.userState.initialize();
        void this.bookShelfService.initialize();
      }

      this.mixpanelService.reset(); // Reset the user in MixPanel
      redirectToHome && void this.navigationService.navigate('/home');
    }
  }

  async login(user: User): Promise<void> {
    if (this.userState.user$) {
      user = {
        ...user,
        guestCredentials: {
          id: this.userState.user$.value.id,
          jwt: this.userState.user$.value.jwt,
        },
      };
    }

    await this.userState.set(user);
    await this.bookShelfService.initialize();
    await this.profileService.refreshProfile();
    await this.mixpanelService.identify(user.id);
  }
}
