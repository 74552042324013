@if (book) {
  <div class="book" [routerLink]="'/book/' + book.uuid + '/details'" [class.hidden]="hidden" [class.desktop]="(appService.isMobile$ | async) === false">
    <img [src]="book | compressedImage" alt="{{ book.title }}" class="fade-image cover" (load)="onLoad('image' + book.uuid + type)" [id]="'image' + book.uuid + type" />
    <div class="book-title" [class.blue-theme]="theme === 'blue'">
      {{ book.title }}
    </div>
  </div>
} @else {
  <div class="book">
    <img class="fade-image cover" />
    <div class="book-title" [class.blue-theme]="theme === 'blue'">Book title place holder to calculate the swiper height</div>
  </div>
}
